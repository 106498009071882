import axios from 'axios';
import qs from 'qs';
import move from 'lodash-move';

import { toast } from 'components/elements/molecules/Toast/Toast';
import download from 'downloadjs';

/*** Constants */
export const LOAD_POINT = 'LOAD_POINT';
export const UPDATE_POINT = 'UPDATE_POINT';
export const UPDATE_POINTS = 'UPDATE_POINTS';
export const POINT_CREATED = 'POINT_CREATED';
export const POINT_REVIEWED = 'POINT_REVIEWED';
export const POINT_CHALLENGED = 'POINT_CHALLENGED';
export const POINT_AUTHORS_INVITED = 'POINT_AUTHORS_INVITED';
export const POINT_AUTHORS_ACCEPTED = 'POINT_AUTHORS_INVITED_ACCEPTED';
export const NEW_POINT_LIST_ITEMS = 'NEW_POINT_LIST_ITEMS';

export const POINT_ERROR = 'POINT_ERROR';

export const UPDATE_MESSAGE_THREAD = 'UPDATE_MESSAGE_THREAD';
// export const REORDER_POINT = 'REORDER_POINT';
// export const REORDER_POINT_ERROR = 'REORDER_POINT_ERROR';

export const MOVE_SUBPOINT = 'MOVE_SUBPOINT';
export const FOLLOW_COLLECTION = 'FOLLOW_COLLECTION';
export const UNFOLLOW_COLLECTION = 'UNFOLLOW_COLLECTION';
export const FOLLOW_POINT = 'FOLLOW_POINT';
export const UNFOLLOW_POINT = 'UNFOLLOW_POINT';

export const TOGGLE_SELECT_POINT = 'TOGGLE_SELECT_POINT';
export const CLEAR_POINT_SELECTIONS = 'CLEAR_POINT_SELECTIONS';
export const LOAD_DUPLICATE_POINTS = 'LOAD_DUPLICATE_POINTS';

export function pointError(errorMessage, pointId) {
  return {
    type: POINT_ERROR,
    errorMessage,
    pointId,
  };
}

/*** Action Creators */
export function loadPoint(pointObject, user) {
  return {
    type: LOAD_POINT,
    pointObject,
    user,
  };
}

export function pointCreated(point) {
  return {
    type: POINT_CREATED,
    point,
  };
}

export function updatePoint(point, user) {
  return {
    type: UPDATE_POINT,
    point,
    user,
  };
}

export function moveSubPointOnState(dragParent, targetParent) {
  return {
    type: MOVE_SUBPOINT,
    dragParent,
    targetParent,
  };
}

// export function reorderPointAction(point, subPoints) {
//   return {
//     type: UPDATE_POINT,
//     point,
//     subPoints
//   }
// }

// export function reorderPointAction(sourceParentPoint, targetParentPoint) {
//     return {
//       type: UPDATE_POINT,
//       sourceParentPoint,
//       targetParentPoint
//     }
//   }

export function pointReviewed(point, newPointReview, updatedPointReviews) {
  return {
    type: POINT_REVIEWED,
    point,
    newPointReview,
    updatedPointReviews,
  };
}

export function pointChallenged(point, newPointReview, updatedPointReviews) {
  return {
    type: POINT_CHALLENGED,
    point,
    newPointReview,
    updatedPointReviews,
  };
}

export function subPointAddedToPoint(updatedPoints) {
  return {
    type: UPDATE_POINTS,
    updatedPoints,
  };
}

export function subPointRemovedFromPoint(updatedPoints) {
  return {
    type: UPDATE_POINTS,
    updatedPoints,
  };
}

export function subPointMovedOnServer(updatedPoints) {
  return {
    type: UPDATE_POINTS,
    updatedPoints,
  };
}

export function pointAuthorsInvited(point) {
  return {
    type: POINT_AUTHORS_INVITED,
    point,
  };
}

export function pointAuthorsAccepted(point, invitationStatus, permissionLevel) {
  return {
    type: POINT_AUTHORS_ACCEPTED,
    point,
    invitationStatus,
    permissionLevel,
  };
}

export function newPointListItemsReceived(newPointListItems) {
  return {
    type: NEW_POINT_LIST_ITEMS,
    newPointListItems,
  };
}

export function updateMessageThread(
  pointId,
  reviewId,
  messageThreadId,
  messageThread,
) {
  return {
    type: UPDATE_MESSAGE_THREAD,
    pointId,
    reviewId,
    messageThreadId,
    messageThread,
  };
}

export function loadDuplicatePoints(duplicatePoints) {
  return {
    type: LOAD_DUPLICATE_POINTS,
    duplicatePoints,
  };
}

/*** Actions */

export function createPoint(point, additionalAuthors, collectionId) {
  return (dispatch, getState) => {
    console.log(`saving point with the fact ${JSON.stringify(point)}`);

    dispatch({
      type: 'START_SAVING_POINT',
      pointId: point._id,
    });

    return new Promise((resolve, reject) => {
      // let res;
      // if (collectionId === 'Mock-Point-1') {
      if (
        (collectionId &&
          (collectionId.indexOf('Mock-Point') === 0 ||
            collectionId.indexOf('example') === 0)) ||
        point.isMock
      ) {
        point._id = Date.now().toString();
        point.subPoints = [];
        point.numQTP = 0;
        point.numRTP = 0;
        point.numShares = 0;
        dispatch(pointCreated(point));
        resolve(point);
      } else {
        axios({
          method: 'post',
          url: '/api/points/createPoint',
          data: {
            point,
            additionalAuthors,
          },
        })
          .then((response) => {
            console.log('Create point responded successfully');
            console.log(response);
            let data = response.data;
            dispatch(pointCreated(data.point));
            resolve(data.point);
          })
          .catch((response) => {
            console.log('Create Point responded error');
            console.log(response);
            reject(response);
          });
      }
    });
  };
}

export function savePoint(point, additionalAuthors) {
  return (dispatch, getState) => {
    console.log(`saving point with the fact ${JSON.stringify(point)}`);

    dispatch({
      type: 'START_SAVING_POINT',
      pointId: point._id,
    });

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/points/savePoint',
        data: {
          point,
          additionalAuthors,
        },
      })
        .then((response) => {
          console.log('Save point responded successfully');
          console.log(response);
          let data = response.data;
          if (data.isNew) dispatch(pointCreated(data.point));
          else dispatch(updatePoint(data.point, getState().user));

          resolve(data.point);
        })
        .catch((response) => {
          console.log('Save Point responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function getNextPointListForReadingPage(
  hasNewTags,
  tags,
  skip,
  limit,
  getTagList,
  sort,
) {
  return (dispatch, getState) => {
    console.log(`get PointList with the tags ${JSON.stringify(tags)}`);

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/points/getNextPointList',
        params: {
          hasNewTags,
          tags,
          skip,
          limit,
          getTagList,
          sort,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          console.log('getPointsForReadingPage responded successfully');
          console.log(response);
          let data = response.data;

          dispatch(newPointListItemsReceived(data.newPointListItems));

          resolve(data.newPointListItems);
        })
        .catch((response) => {
          console.log('getPointsForReadingPage responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function getPoint(pointId, parentType, parentId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      // dispatch({
      //   type: 'START_GETTING_POINT',
      //   pointId,
      // });
      if (
        pointId &&
        (pointId.toString().includes('Mock') ||
          pointId.includes('example') ||
          (getState().points[pointId] &&
            getState().points[pointId].point.isMock))
      ) {
        const point = getState().points[pointId];
        resolve(point);
        return;
      }

      axios({
        method: 'get',
        url: '/api/points/getPoint',
        params: {
          id: pointId,
          parentType,
          parentId,
        },
      })
        //.get(api, { data: { id: pointId, parentType, parentId } })
        .then((response) => {
          //console.log("OG responded successfully")
          //console.log(response);

          let data = response.data;

          let pointObject = {
            point: data.point,
            suggestedRevisions: data.suggestedRevisions || [],
            reviews: data.pointReviews || [],
            userPointReview: data.userPointReview,
            subPoints: data.subPoints,
            supporters: data.supporters,
            opponents: data.opponents,
            userSupports: data.userSupports,
            userOpposes: data.userOpposes,
            // userPortfolio: data.userPortfolio,
            permissionLevel: data.permissionLevel,
            invitationStatus: data.invitationStatus,
            following: data.following,
          };

          dispatch(loadPoint(pointObject, getState().user));
          resolve(pointObject);
        })
        .catch((response) => {
          console.log('Load Point responded error');
          console.log(response);
          dispatch(pointError(response.response.data.error, pointId));
          //reject(response.response);
        });
    });
  };
}

export function getPointNotificationPreferences(pointId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/pointAuthors/getPointNotificationPreferences',
        params: {
          id: pointId,
        },
      })
        .then((response) => {
          let data = response.data;
          dispatch({
            type: 'UPDATE_POINT',
            point: {
              _id: pointId,
              notificationPreferences: data.notificationPreferences,
            },
          });
        })
        .catch((response) => {
          console.log('Get PointNotifications responded error');
          console.log(response);
          dispatch(pointError(response.response.data.error, pointId));
        });
    });
  };
}

export function setPointNotificationPreferences(pointId, preferences) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/pointAuthors/setPointNotificationPreferences',
        data: {
          pointId,
          preferences,
        },
      })
        .then((response) => {
          let data = response.data;
          dispatch({
            type: 'UPDATE_POINT',
            point: {
              _id: pointId,
              notificationPreferences: data.notificationPreferences,
            },
          });
        })
        .catch((response) => {
          console.log('Set PointNotifications responded error');
          console.log(response);
          dispatch(pointError(response.response.data.error, pointId));
        });
    });
  };
}

export function publishPoint(pointId) {
  return (dispatch, getState) => {
    console.log(`publishing point with the id ${JSON.stringify(pointId)}`);

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/points/publishPoint',
        data: {
          pointId,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          console.log('Publish point responded successfully');
          console.log(response);
          let data = response.data;

          dispatch(updatePoint(data.point, getState().user));

          resolve(data.point);
        })
        .catch((response) => {
          console.log('Update Point responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function removeAuthorFromPoint(pointId, removeUsername) {
  return (dispatch, getState) => {
    console.log(`removing author from point id ${JSON.stringify(pointId)}`);

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/points/removeAuthorFromPoint',
        data: {
          pointId,
          removeUsername,
        },
      })
        .then((response) => {
          console.log('Remove author from Point responded successfully');
          console.log(response);
          let data = response.data;

          dispatch(updatePoint(data.point, getState().user));

          resolve(data.point);
        })
        .catch((response) => {
          console.log('Remove Author from Point responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function revisePoint(pointId, revisionReason, makePrivate) {
  return (dispatch, getState) => {
    console.log(`publishing point with the id ${JSON.stringify(pointId)}`);

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/points/revisePoint',
        data: {
          pointId,
          revisionReason,
          makePrivate,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          console.log('Revise point responded successfully');
          console.log(response);
          let data = response.data;

          dispatch(updatePoint(data.point, getState().user));

          resolve(data.point);
        })
        .catch((response) => {
          console.log('Revise Point responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function saveSubPointToPoint(
  subPointId,
  pointId,
  stance,
  // timeStamp,
  subPointIds,
  captions,
) {
  return (dispatch, getState) => {
    console.log(`saving point to point with the id ${JSON.stringify(pointId)}`);

    return new Promise((resolve, reject) => {
      let res;
      // if (pointId === 'Mock-Point-1') {
      if (
        pointId.indexOf('Mock-Point') === 0 ||
        pointId.indexOf('example') === 0 ||
        (getState().points[pointId] && getState().points[pointId].point.isMock)
      ) {
        const pointObject = getState().points[pointId];
        const subPointObject = getState().points[subPointId];
        res = addMockSubPoint(
          pointObject.point,
          subPointObject.point,
          stance,
          subPointIds,
        );
        resolve(res.updatedPoints[0]);
        dispatch(subPointAddedToPoint(res.updatedPoints));
      } else {
        axios({
          method: 'post',
          url: '/api/points/saveSubPointToPoint',
          data: {
            subPointId,
            pointId,
            stance,
            // timeStamp,
            subPointIds,
            captions,
          },
          paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          },
        })
          .then((response) => {
            console.log('Save subpoint to point responded successfully');
            console.log(response);
            let data = response.data;
            dispatch(subPointAddedToPoint(data.updatedPoints));
            resolve();
            //dispatch(updatePoint(data.point));

            resolve(data.point);
          })
          .catch((response) => {
            console.log('Save SubPoint to Point responded error');
            console.log(response);
            toast(response.response.data.error, {}, 'error');
            reject(response);
          });
      }
    });
  };
}

function addMockSubPoint(point, subPoint, stance, subPointIds) {
  const subPointInfo = {
    _id: `Mock-SubPoint-${Date.now()}`,
    pointId: subPoint._id,
    stance,
    postedBy: point.authorId,
    postedByName: point.authorName,
  };
  point.subPoints.push(subPointInfo);
  const updatedPoints = [point, subPoint];
  return { updatedPoints };
}

export function removeSubPointFromPoint(subPointId, pointId, type) {
  return (dispatch, getState) => {
    console.log(
      `removing fact from point with the id ${JSON.stringify(pointId)}`,
    );

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/points/removeSubPointFromPoint',
        data: {
          subPointId,
          pointId,
        },
      })
        .then((response) => {
          console.log('Remove SubPoint from point responded successfully');
          //TODO - add the stance
          console.log(response);
          let data = response.data;
          dispatch(subPointRemovedFromPoint(data.updatedPoints));
          if (type == 'collection') {
            toast('Point removed from your Collection.');
          } else {
            toast('SubPoint removed from your Point.');
          }
          resolve();
          //dispatch(updatePoint(data.point));

          resolve(data.point);
        })
        .catch((response) => {
          console.log('Remove SubPoint from point responded error');
          console.log(response);
          toast(response.response.data.error, {}, 'error');
          reject(response);
        });
    });
  };
}

export function updateSubPointOfPoint(pointId, subPointObject) {
  return (dispatch, getState) => {
    console.log(`saving point to point with the id ${JSON.stringify(pointId)}`);

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/points/updateSubPointOfPoint',
        data: {
          pointId,
          subPointObject,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          console.log('updateSubPointOfPoint responded successfully');
          console.log(response);
          let data = response.data;
          dispatch(updatePoint(data.point, getState().user));
          resolve();
          //dispatch(updatePoint(data.point));

          resolve(data.point);
        })
        .catch((response) => {
          console.log('updateSubPointOfPoint responded error');
          console.log(response);
          toast(response.response.data.error, {}, 'error');
          reject(response);
        });
    });
  };
}

export function deletePoint(pointId) {
  return (dispatch, getState) => {
    console.log(`deleting point with the id ${JSON.stringify(pointId)}`);

    return new Promise((resolve, reject) => {
      axios({
        method: 'delete',
        url: `/api/points/${pointId}/delete`,
        params: {
          pointId,
        },
      })
        .then((response) => {
          console.log('Delete Point responded successfully');
          console.log(response);
          let data = response.data;
          dispatch({
            type: 'DELETE_POINTS',
            deletedPointIds: [data.pointId],
          });
          dispatch({
            type: 'UPDATE_POINTS',
            updatedPoints: data.updatedPoints,
          });

          toast('Deleted your Point.');
          resolve();
        })
        .catch((response) => {
          console.log('Delete point responded error');
          console.log(response);
          toast(response.response.data.error, {}, 'error');
          reject(response);
        });
    });
  };
}

export function invitePoint(
  point,
  users,
  permissionLevel,
  inviteOption = 'InviteChildren',
) {
  return (dispatch, getState) => {
    console.log(
      `inviting users to the point with the id ${JSON.stringify(point._id)}`,
    );

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/points/invitePoint',
        data: {
          point,
          users,
          permissionLevel,
          inviteOption,
        },
      })
        .then((response) => {
          console.log('InvitePoint responded successfully');
          console.log(response);
          let data = response.data;

          dispatch(pointAuthorsInvited(data.point));
          if (data.updatedPoints && data.updatedPoints.length > 0) {
            dispatch({
              type: 'UPDATE_POINTS',
              updatedPoints: data.updatedPoints,
            });
          }
          toast('Your invitation was sent');
          resolve(data);
        })
        .catch((response) => {
          console.log('InvitePoint responded error');
          console.log(response);
          toast(response.response.data.error, {}, 'error');
          //dispatch(pointError(response.response.data.error));
          reject(response);
        });
    });
  };
}

export function respondInvitePoint(pointId, userResponse) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/points/respondInvitePoint',
        data: {
          pointId,
          userResponse,
        },
      })
        .then((response) => {
          console.log('RespondInvitePoint responded successfully');
          console.log(response);
          const data = response.data;
          dispatch({
            type: 'UPDATE_POINT',
            point: data.point,
            invitationStatus: data.invitationStatus,
            permissionLevel: data.permissionLevel,
            user: getState().user,
          });
          if (data.point.isCollection && data.userResponse === 'accepted') {
            dispatch({
              type: 'UPDATE_PORTFOLIO',
              portfolio: data.portfolio,
            });
          }
          const type = data.point.isCollection ? 'collection' : 'point';
          toast(`You ${data.userResponse} your invite to the ${type}.`);
          resolve(data.point);
        })
        .catch((response) => {
          console.log('RespondInvitePoint responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function submitFactReview(
  point,
  userReview,
  neutralLanguage,
  verifiedInSource,
  userPointReview,
  messageThreadId,
) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/reviews/submitFactReview',
        params: {
          point,
          userReview,
          neutralLanguage,
          verifiedInSource,
          userPointReview,
          messageThreadId,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          console.log('Submitted Review Successfully');
          const data = response.data;
          dispatch(
            pointReviewed(
              data.point,
              data.newPointReview,
              data.updatedPointReviews,
            ),
          );
          resolve(data.newPointReview);
        })
        .catch((response) => {
          console.log('Submit Review responded error');
          reject();
        });
    });
  };
}

export function challengeFactReview(
  point,
  userReview,
  neutralLanguage,
  verifiedInSource,
  userPointReview,
  messageThreadId,
) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/reviews/challengeFactReview',
        params: {
          point,
          userReview,
          neutralLanguage,
          verifiedInSource,
          userPointReview,
          messageThreadId,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          console.log('Challenge Fact submitted successfully');
          const data = response.data;
          dispatch(
            pointChallenged(
              data.updatedPoint,
              data.newPointReview,
              data.updatedPointReviews,
            ),
          );
          resolve(data.updatedReviews);
        })
        .catch((response) => {
          console.log('Challenge Fact review responded error');
          reject();
        });
    });
  };
}

export function submitOpinionReview(
  point,
  userReview,
  balancedLanguage,
  factsSupportClaims,
  userPointReview,
  messageThreadId,
) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/reviews/submitOpinionReview',
        params: {
          point,
          userReview,
          balancedLanguage,
          factsSupportClaims,
          userPointReview,
          messageThreadId,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          console.log('Submitted Review Successfully');
          const data = response.data;
          dispatch(pointReviewed(data.point, data.newPointReview));
          resolve(data.newPointReview);
        })
        .catch((response) => {
          console.log('Submit opinion review responded error');
          reject();
        });
    });
  };
}

export function lookupURL(value) {
  return (dispatch, getState) => {
    var method = 'GET';
    var api = '/api/utility/lookupURL';
    var data = { URLtoFetch: value };

    return new Promise((resolve, reject) => {
      axios
        .get(api, { params: data })
        .then((response) => {
          console.log(response);
          let data = response.data;
          if (!data.success) reject(data.errorMessage);
          else {
            console.log('OG responded successfully');
            resolve(data);
          }
        })
        .catch((response) => {
          console.log('OG responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function sendMessage(pointReviewId, note, proposedPointId, mainPointId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/messages/sendMessage',
        params: {
          pointReviewId,
          note,
          proposedPointId,
          mainPointId,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          dispatch(
            updateMessageThread(
              mainPointId,
              pointReviewId,
              response.data.messageThread._id,
              response.data.messageThread,
            ),
          );
          console.log('Message send Successfully');
          const data = response.data;
          resolve(data.messageThread);
        })
        .catch((response) => {
          reject();
        });
    });
  };
}

export function getMessageThread(pointId, pointReviewId, messageThreadId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/messages/getMessageThread',
        params: {
          messageThreadId,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          console.log('Got message thread successfully');
          const data = response.data;
          dispatch(
            updateMessageThread(
              pointId,
              pointReviewId,
              messageThreadId,
              data.messageThread,
            ),
          );
          resolve(data.messageThread);
        })
        .catch((response) => {
          reject();
        });
    });
  };
}

export function reorderPointOnState(dragParent, targetParent) {
  console.log(`In reorder Point and the targetParentId is ${targetParent.id}`);

  return (dispatch, getState) => {
    if (
      !dragParent.id ||
      isNaN(dragParent.index) ||
      !targetParent.id ||
      isNaN(targetParent.index)
    ) {
      console.log('Move card on hover got called by args are null:');
      console.log(
        `dragParentId: ${dragParent.id} sourceParentIndex: ${dragParent.index} targetParentId: ${targetParent.id} targetIndex: ${targetParent.index}`,
      );
      return;
    }

    dispatch(moveSubPointOnState(dragParent, targetParent));
  };
}

export function moveSubPointOnServer(
  // sourceParentId,
  // sourceIndex,
  //targetParentId,
  //targetIndex,
  //targetParentType,
  parentOption, //dragParent, targetParent
) {
  console.log('In moveSubPointOnServer');

  return (dispatch, getState) => {
    const state = getState();

    const dnd = state.page.dnd;
    console.log('sourceParent is:');
    console.log(dnd.sourceInfo.originalParent);
    const sourceParentId = dnd.sourceInfo.originalParent.id;
    const sourceIndex = dnd.sourceInfo.originalParent.index;
    const sourceParentType = dnd.sourceInfo.originalParent.type;

    const targetParent =
      parentOption == 'drag' ? dnd.sourceInfo.dragParent : dnd.targetParent;
    console.log('targetParent is:');
    console.log(targetParent);

    //Get the final list of subPoints for a drag
    let finalSubPoints = null;
    if (parentOption == 'drag') {
      if (targetParent.type == 'portfolio') {
        finalSubPoints =
          state.portfolios[targetParent.id].portfolio.collectionPoints;
      } else {
        finalSubPoints = state.points[targetParent.id].point.subPoints;
      }
    }

    if (
      !sourceParentId ||
      isNaN(sourceIndex) ||
      !targetParent.id ||
      isNaN(targetParent.index)
    ) {
      console.log(
        'Trying to move SubPoints on server but sourceParent or sourceIndex are not set correctly. They are...',
      );
      console.log(state.page.dnd.sourceInfo.originalParent);
      return null;
    }

    dispatch({
      type: 'CLEAR_DND',
    });

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/points/moveSubPoint',
        data: {
          sourceParentId,
          sourceIndex,
          sourceParentType,
          targetParentId: targetParent.id,
          targetIndex: targetParent.index,
          targetParentType: targetParent.type,
          finalSubPoints,
        },
      })
        .then((response) => {
          console.log('Move subPoints on server responded successfully');
          const data = response.data;
          dispatch(subPointMovedOnServer(data.updatedPoints));
          if (data.updatedPortfolio) {
            dispatch({
              type: 'UPDATE_PORTFOLIO',
              portfolio: data.updatedPortfolio,
            });
          }
          console.log(`Updating ${data.updatedPoints.length} points `);
        })
        .catch((response) => {
          //TODO - move the points back
          reject();
        });
    });
  };
}

export function movePoint(req) {
  return async (dispatch, getState) => {
    const response = await axios({
      method: 'post',
      url: '/api/points/moveSubPoint',
      data: req,
    });
    const data = response.data;
    dispatch(subPointMovedOnServer(data.updatedPoints));
  };
}

export function downloadPoint(pointId, subPointOption) {
  console.log(`In downloadPoint and the pointId is ${pointId}`);

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      //dispatch file download started
      axios({
        method: 'post',
        url: '/api/utility/downloadPoint',
        data: {
          pointId,
          subPointOption,
        },
      })
        .then((response) => {
          console.log('DownloadPoint responded successfully');
          const data = response.data;
          const point = data.point;
          const filename = `${point.text.substring(0, 20)}.csv`;

          download(data.csv, filename, 'text/csv');
          //dispatch download success
        })
        .catch((response) => {
          console.log('DownloadPoint error');
          //TODO - move the points back
          //dispatch download error
          reject();
        });
    });
  };
}

export function followCollection(id) {
  return (dispatch) => {
    return axios
      .post(`/api/collections/${id}/follow`)
      .then(() => dispatch({ type: FOLLOW_COLLECTION, id }));
  };
}

export function unFollowCollection(id) {
  return (dispatch) => {
    return axios
      .delete(`/api/collections/${id}/follow`)
      .then(() => dispatch({ type: UNFOLLOW_COLLECTION, id }));
  };
}

export function followPoint(id) {
  return (dispatch) => {
    return axios
      .post(`/api/points/${id}/follow`)
      .then(() => dispatch({ type: FOLLOW_POINT, id }));
  };
}

export function unfollowPoint(id) {
  return (dispatch) => {
    return axios
      .delete(`/api/points/${id}/follow`)
      .then(() => dispatch({ type: UNFOLLOW_POINT, id }));
  };
}

export const toggleSelectPoint = (point) => ({
  type: TOGGLE_SELECT_POINT,
  point,
});

export const clearSelections = () => ({
  type: CLEAR_POINT_SELECTIONS,
});

export function requestFor(pointId, forField, timestamp, note) {
  return (dispatch, getState) => {
    console.log(`requesting info for the Point ${JSON.stringify(pointId)}`);
    const request = {
      subjectId: pointId,
      requestFor: forField,
      meta: {
        timestamp,
      },
      note,
    };
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/points/requestFor',
        data: {
          request,
        },
      })
        .then((response) => {
          console.log('RequestFor responded with success');
          console.log(response);
          const data = response.data;
          const requestsFor = getState().points[pointId].requestsFor || [];
          requestsFor.push(request);
          dispatch({
            type: 'UPDATE_POINT',
            point: data,
            user: getState().user,
            requestsFor,
          });
          const type = data.isCollection ? 'collection' : 'point';
          toast(`Your request for ${forField} for this ${type} was submitted`);
          resolve(data);
        })
        .catch((response) => {
          console.log('RequestFor responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function getPointRequestsFor(pointId) {
  return async (dispatch, getState) => {
    const res = await axios({
      method: 'get',
      url: `/api/requests/${pointId}`,
    });
    const point = getState().points[pointId].point;
    dispatch({ type: 'UPDATE_POINT', point, requestsFor: res.data });
  };
}

export function getDuplicatePoints(date, sourceName) {
  return (dispatch) => {
    console.log('getting getDuplicatePoints');

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/diagnosis/getDuplicatePoints',
        params: { fromDate: date, sourceName },
      })
        .then((response) => {
          console.log('getDuplicatePoints responded successfully');
          console.log(response);
          let data = response.data;
          dispatch(loadDuplicatePoints(data));
          resolve();
        })
        .catch((error) => {
          console.log('getDuplicatePoints responded error');
          console.log(error);
          toast('Failed to load duplicate points. Please try again later.');
          resolve();
        });
    });
  };
}

export function getArticleMediaCollectionWithSourceInfo(
  sourceURL,
  pointCreationFeature,
) {
  return (dispatch) => {
    console.log('getting getDuplicatePoints');

    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: '/api/v2/mediaCollections/getArticleMediaCollectionWithSourceInfo',
        data: {
          sourceURL,
          pointCreationFeature,
        },
      })
        .then((response) => {
          console.log(response);
          const data = response.data;
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          toast('Failed to add article. Please try again later.');
          resolve();
        });
    });
  };
}
