import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';

import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';
import Questions from 'components/elements/organisms/Questions/Questions';

import './ManageQuizQuestions.scss';
import { getLogo } from 'helpers/sourceIcon';

const stanfordCORQuestions = [
  {
    text: "Who's behind the information?",
    type: 'SHORT_ANSWER',
    options: [],
    isStanfordCOR: true,
    typeOfQuestion: 'libraryScience',
  },
  {
    text: "What's the evidence?",
    type: 'SHORT_ANSWER',
    options: [],
    isStanfordCOR: true,
    typeOfQuestion: 'libraryScience',
  },
  {
    text: 'What do other sources say?',
    type: 'SHORT_ANSWER',
    options: [],
    isStanfordCOR: true,
    typeOfQuestion: 'libraryScience',
  },
];

function ManageQuizQuestions({ readings, course, onClose, actions }) {
  const [error, setError] = useState('');

  const submitQuestions = (reading, questions) => {
    const deletedQuestions = [];

    if (Array.isArray(reading.questions)) {
      for (const existingQuestion of reading.questions) {
        const doesExist = questions.find((q) => q._id === existingQuestion._id);

        if (!doesExist) {
          deletedQuestions.push(existingQuestion._id);
        }
      }
    }

    if (reading.topicId) {
      actions
        .addReadingsQuestions(
          reading.topicId,
          reading.courseId,
          reading.pointId,
          questions,
          deletedQuestions,
        )
        .then(() => onClose())
        .catch((error) => {
          setError(
            error.message ||
              'Error occurred while adding quiz questions. Please try again.',
          );
        });
    } else {
      actions
        .addPointQuestions(reading.pointId, questions, deletedQuestions)
        .then(() => onClose())
        .catch((error) => {
          setError(
            error.message ||
              'Error occurred while adding quiz questions. Please try again.',
          );
        });
    }
  };

  const showReadings = () => {
    return readings.map((reading) => (
      <ReadingQuestions
        reading={reading}
        submitQuestions={submitQuestions}
        actions={actions}
        course={course}
      />
    ));
  };

  return (
    <Modal show={true} className="manage-quiz-modal" id="manage-quiz-modal">
      <Modal.Body>
        <div className="header mb-4">
          <span>Add Reading Quiz</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        {showReadings()}
      </Modal.Body>
    </Modal>
  );
}

export function ReadingQuestions({
  reading,
  submitQuestions,
  course,
  actions,
}) {
  const [questions, setQuestions] = useState(reading.questions || []);
  const [isLoading, setIsLoading] = useState(false);
  const [generatingQuestions, setGeneratingQuestions] = useState(false);

  useEffect(() => {
    if (questions.length === 0) {
      setIsLoading(true);

      actions
        .getQuizQuestionsForArticle(
          reading.pointId,
          reading.courseId,
          reading.topicId,
        )
        .then((response) => {
          if (Array.isArray(response.questions)) {
            const generatedQuestions = _.cloneDeep(response.questions);

            setQuestions(() => [...generatedQuestions]);
          }

          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (questions !== reading.questions && questions.length > 0) {
      const lastQuestion = questions[questions.length - 1];

      if (
        !lastQuestion.isGenerated &&
        !lastQuestion.isStanfordCOR &&
        lastQuestion.typeOfQuestion !== 'selfReflection'
      ) {
        scrollToElement();
      }
    }
  }, [questions]);

  const addStanfordCORQuestions = () => {
    setQuestions((questions) => [...questions, ...stanfordCORQuestions]);
  };

  const generateQuestions = () => {
    setGeneratingQuestions(true);

    actions
      .generateQuestionsFromSource(reading.url, reading.pointId)
      .then((questions) => {
        setGeneratingQuestions(false);

        if (Array.isArray(questions)) {
          const generatedQuestions = questions
            .filter((q) => q.question)
            .map((question) => ({
              text: question.question,
              type:
                (question.options || []).length > 0
                  ? 'SINGLE_CHOICE'
                  : 'SHORT_ANSWER',
              answer: question.answer,
              options: question.options || [],
              isGenerated: true,
              typeOfQuestion: 'comprehension',
              gptGeneratedQuestion:
                question.gptGeneratedQuestion || question.question,
              gptGeneratedAnswer:
                question.gptGeneratedAnswer || question.answer,
            }));

          setQuestions((questions) => [...questions, ...generatedQuestions]);
        }
      })
      .catch((error) => {
        setGeneratingQuestions(false);
      });
  };

  const scrollToElement = () => {
    const element = document.getElementById('manage-quiz-modal');
    if (element) {
      element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
    }
  };

  const addQuestion = () => {
    const clonedQuestions = _.cloneDeep(questions) || [];

    clonedQuestions.push({
      text: '',
      type: 'SINGLE_CHOICE',
      options: [],
      typeOfQuestion: 'comprehension',
      isTeacherCreated: true,
    });

    setQuestions(clonedQuestions);
  };

  const showButtons = () => {
    if (isLoading) {
      return null;
    }

    return (
      <div>
        <div className="font-weight-600 mt-4">
          Build your quiz by clicking on the options
        </div>
        <div>
          <RectangularButton
            className="add-question-button mt-2"
            iconName="ADD"
            iconSize={12}
            onClick={addQuestion}
            lightButton
          >
            Your Question
          </RectangularButton>
          <RectangularButton
            className={`add-question-button generate-question mt-2 ml-3`}
            iconName={generatingQuestions ? null : 'ADD'}
            iconSize={12}
            onClick={generateQuestions}
            lightButton
            disabled={questions.some((q) => q.isGenerated)}
          >
            <span className={`${generatingQuestions ? 'hide-label' : ''}`}>
              AI Questions
            </span>
            {generatingQuestions && (
              <div
                className="fa fa-spin fa-spinner text-primary mt-2 generate-question-loader"
                style={{ fontSize: '16px' }}
              />
            )}
          </RectangularButton>
          <RectangularButton
            className="add-question-button mt-2 ml-3"
            iconName="ADD"
            iconSize={12}
            onClick={addStanfordCORQuestions}
            lightButton
            disabled={questions.some((q) => q.isStanfordCOR)}
          >
            Stanford COR
          </RectangularButton>
        </div>
      </div>
    );
  };

  const showMessageForGeneratedQuestions = () => {
    if (isLoading || questions.length === 0) {
      return null;
    }

    if (!course || !course.courseSettings) {
      return (
        <div className="my-2">
          We generated a quiz with {questions.length} questions using the
          default rules. You can configure the quiz for articles in this course
          by visiting the course page.
        </div>
      );
    }

    const isModified = questions.some((q) => q.isModifiedByTeacher);

    if (isModified) {
      return (
        <div className="my-2">
          We generated a quiz with {questions.length} questions using the rules
          you configured in the course page. You have modified the quiz since
          then.
        </div>
      );
    }

    return (
      <div className="my-2">
        We generated a quiz with {questions.length} questions using the rules
        you configured in the course page. Students will see{' '}
        {course.courseSettings.comprehensionQuestions} comprehension,{' '}
        {course.courseSettings.libraryScienceQuestions} library science, and{' '}
        {course.courseSettings.selfReflectionQuestions} self-reflection
        questions. You can modify the questions below.
      </div>
    );
  };

  return (
    <div className="reading-questions">
      <table>
        <tbody>
          <tr className="readings-container">
            <td className="pr-3 reading-status-column">
              <div className="reading-status">
                <img
                  className="mr-2 source-logo"
                  src={getLogo({ domain: reading.url })}
                />
              </div>
            </td>
            <td className="readings" key={reading.title}>
              <div className="reading-title">
                <div className="font-weight-600">{reading.sourceName}</div>
                <div>{reading.title}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {showButtons()}
      {showMessageForGeneratedQuestions()}
      {isLoading && (
        <div className="text-center my-2">
          <div
            className="fa fa-spin fa-spinner text-primary mt-2 generate-question-loader"
            style={{ fontSize: '20px' }}
          />
          {reading.topicId && (
            <span className="ml-2">
              Building the quiz using your course configuration rules
            </span>
          )}
        </div>
      )}
      <Questions
        questions={questions}
        changeQuestions={setQuestions}
        generatingQuestions={false}
      />
      <div className="mt-5 next-button-container" id="quiz-save-button">
        <RectangularButton
          className="next-button"
          disabled={!questions.length}
          onClick={() => submitQuestions(reading, questions)}
        >
          Save Quiz
        </RectangularButton>
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  let courseId = ownProps.courseId;

  if (!courseId && ownProps.readings && ownProps.readings.length > 0) {
    courseId = ownProps.readings[0].courseId;
  }

  const course = state.classRoom.courses.find((c) => c.courseId === courseId);

  return {
    course,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageQuizQuestions);
