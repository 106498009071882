/* eslint-disable react/prefer-stateless-function */
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Switch, Redirect, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import Spin from './components/elements/atoms/loading';
import SideBar from 'routes/Admin/SideBar/SideBar';

import 'routes/Admin/css/adminRoutes.scss';

const Loading = () => <Spin spinning className="py-3" />;

const AuthRoute = Loadable({
  loader: () => import('./routes/AuthRoute'),
  loading: Loading,
});

const LoginPage = Loadable({
  loader: () => import('./routes/User/Login/LoginContainer'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./routes/Admin/Dashboard/Dashboard'),
  loading: Loading,
});

const CuratorPortal = Loadable({
  loader: () =>
    import('routes/Portfolio/Component/CuratorPortal/CuratorPortal'),
  loading: Loading,
});

const SourceClassifier = Loadable({
  loader: () =>
    import('routes/Portfolio/Component/SourceClassifier/SourceClassifier'),
  loading: Loading,
});

const SubscriptionContainer = Loadable({
  loader: () => import('./routes/Admin/Subscription/SubscriptionContainer'),
  loading: Loading,
});

const RequestedSourcesContainer = Loadable({
  loader: () =>
    import('./routes/Admin/RequestedSources/RequestedSourcesContainer'),
  loading: Loading,
});

const SourceUpdateRequestsContainer = Loadable({
  loader: () =>
    import('./routes/Admin/SourceUpdateRequests/SourceUpdateRequestsContainer'),
  loading: Loading,
});

const ManageSourcesContainer = Loadable({
  loader: () => import('./routes/Admin/ManageSources/ManageSourcesContainer'),
  loading: Loading,
});

const ManageArticlesContainer = Loadable({
  loader: () => import('./routes/Admin/ManageArticles/ManageArticlesContainer'),
  loading: Loading,
});

const ManageDuplicatePointsContainer = Loadable({
  loader: () =>
    import(
      './routes/Admin/ManageDuplicatePoints/ManageDuplicatePointsContainer'
    ),
  loading: Loading,
});

const UserRoleVerificationContainer = Loadable({
  loader: () =>
    import('./routes/Admin/UserRoleVerification/UserRoleVerificationContainer'),
  loading: Loading,
});

const Blogs = Loadable({
  loader: () => import('./routes/Blogs/Blogs'),
  loading: Loading,
});

const Blog = Loadable({
  loader: () => import('./routes/Blog/Blog'),
  loading: Loading,
});

const SourceErrorsContainer = Loadable({
  loader: () => import('./routes/Admin/SourceErrors/SourceErrorsContainer'),
  loading: Loading,
});

const SourceActivitiesContainer = Loadable({
  loader: () =>
    import('./routes/Admin/SourceActivities/SourceActivitiesContainer'),
  loading: Loading,
});

const UserActivitiesDifferences = Loadable({
  loader: () =>
    import(
      './routes/Admin/UserActivitiesDifferences/UserActivitiesDifferences'
    ),
  loading: Loading,
});

const TopicKeywordsContainer = Loadable({
  loader: () => import('./routes/Admin/TopicKeywords/TopicKeywordsContainer'),
  loading: Loading,
});

const QuizQuestionDisputesContainer = Loadable({
  loader: () =>
    import('./routes/Admin/QuizQuestionDisputes/QuizQuestionDisputesContainer'),
  loading: Loading,
});

const AdminSettingsContainer = Loadable({
  loader: () => import('./routes/Admin/AdminSettings/AdminSettings'),
  loading: Loading,
});

const WritingBackupsContainer = Loadable({
  loader: () => import('./routes/Admin/WritingBackups/WritingBackupsContainer'),
  loading: Loading,
});

const DebugLogs = Loadable({
  loader: () => import('./routes/Admin/DebugLogs/DebugLogs'),
  loading: Loading,
});

const AdminCourses = Loadable({
  loader: () => import('./routes/Admin/AdminCourses/AdminCourses'),
  loading: Loading,
});

const UsersContainer = Loadable({
  loader: () => import('./routes/Admin/Users/UsersContainer'),
  loading: Loading,
});

const AllUsers = Loadable({
  loader: () => import('./routes/Admin/Users/AllUsers/AllUsers'),
  loading: Loading,
});

const Writer = Loadable({
  loader: () => import('./routes/Writer/Writer'),
  loading: Loading,
});

const ResearchPrompt = Loadable({
  loader: () => import('./routes/ResearchPrompt/ResearchPrompt'),
  loading: Loading,
});

const Reading = Loadable({
  loader: () => import('./routes/Reading/Reading'),
  loading: Loading,
});

const UnverifiedSchools = Loadable({
  loader: () => import('./routes/Admin/UnverifiedSchools/UnverifiedSchools'),
  loading: Loading,
});

const UnverifiedUserSchools = Loadable({
  loader: () =>
    import('./routes/Admin/UnverifiedUserSchools/UnverifiedUserSchools'),
  loading: Loading,
});

const UnverifiedParents = Loadable({
  loader: () => import('./routes/Admin/UnverifiedParents/UnverifiedParents'),
  loading: Loading,
});

const AllSources = Loadable({
  loader: () => import('./routes/Admin/ManageSources/AllSources/AllSources'),
  loading: Loading,
});

const Articles = Loadable({
  loader: () => import('./routes/Admin/Articles/Articles'),
  loading: Loading,
});

const Classroom = Loadable({
  loader: () => import('./routes/Admin/Classroom/Classroom'),
  loading: Loading,
});

const Teachers = Loadable({
  loader: () => import('./routes/Admin/Classroom/Teachers/Teachers'),
  loading: Loading,
});

const Students = Loadable({
  loader: () => import('./routes/Admin/Classroom/Students/Students'),
  loading: Loading,
});

const Schools = Loadable({
  loader: () => import('./routes/Admin/Schools/Schools'),
  loading: Loading,
});

const AllArticles = Loadable({
  loader: () => import('./routes/Admin/AllArticles/AllArticles'),
  loading: Loading,
});

const ArticleQuizQuestions = Loadable({
  loader: () =>
    import('./routes/Admin/ArticleQuizQuestions/ArticleQuizQuestions'),
  loading: Loading,
});

const Quizzes = Loadable({
  loader: () => import('./routes/Admin/Quizzes/Quizzes'),
  loading: Loading,
});

const QuizTrainingDatasets = Loadable({
  loader: () =>
    import('./routes/Admin/QuizTrainingDatasets/QuizTrainingDatasets'),
  loading: Loading,
});

const ReadingIntervals = Loadable({
  loader: () => import('./routes/Admin/ReadingIntervals/ReadingIntervals'),
  loading: Loading,
});

class AdminRoutes extends React.Component {
  render() {
    const { pathname } = this.props.location;
    let showSidebar = true;

    if (pathname.includes('/login')) {
      showSidebar = false;
    }

    return (
      <Fragment>
        <div className="d-flex">
          {showSidebar && <SideBar pathname={pathname} />}
          <div className={`dashboard ${!showSidebar ? 'no-sidebar' : ''}`}>
            <Switch>
              {/* Auth Routes */}
              <Route exact path="/login" component={LoginPage} />

              {/* Admin Routes */}
              <AuthRoute exact path="/dashboard" component={Dashboard} />
              <AuthRoute exact path="/users" component={UsersContainer} />
              <AuthRoute exact path="/users/all" component={AllUsers} />
              <AuthRoute path="/curation" component={CuratorPortal} />
              <AuthRoute
                path="/sourceClassifier"
                component={SourceClassifier}
              />
              <AuthRoute
                path="/subscription"
                component={SubscriptionContainer}
              />
              <AuthRoute
                path="/requestedSources"
                component={RequestedSourcesContainer}
              />
              <AuthRoute
                path="/sourceUpdateRequests"
                component={SourceUpdateRequestsContainer}
              />
              <AuthRoute
                path="/manageSources"
                component={ManageSourcesContainer}
              />
              <AuthRoute
                path="/manageArticles"
                component={ManageArticlesContainer}
              />
              <AuthRoute
                path="/manageDuplicatePoints"
                component={ManageDuplicatePointsContainer}
              />
              <AuthRoute
                path="/verifyUserRoles"
                component={UserRoleVerificationContainer}
              />
              <AuthRoute path="/blogs" component={Blogs} />
              <AuthRoute path="/blog" component={Blog} />
              <AuthRoute
                path="/topicKeywords"
                component={TopicKeywordsContainer}
              />
              <AuthRoute
                path="/disputedQuizQuestions"
                component={QuizQuestionDisputesContainer}
              />
              <AuthRoute
                path="/admin-settings"
                component={AdminSettingsContainer}
              />
              <AuthRoute
                path="/writingBackups"
                component={WritingBackupsContainer}
              />
              <AuthRoute path="/debugLogs" component={DebugLogs} />
              <AuthRoute path="/admin-courses" component={AdminCourses} />
              <AuthRoute
                path="/sourceErrors"
                component={SourceErrorsContainer}
              />
              <AuthRoute
                path="/sourceActivities"
                component={SourceActivitiesContainer}
              />
              <AuthRoute
                path="/userActivityCorrections"
                component={UserActivitiesDifferences}
              />
              <AuthRoute path="/writer" component={Writer} />
              <AuthRoute path="/researchPrompt" component={ResearchPrompt} />
              <AuthRoute path="/reading" component={Reading} />
              <AuthRoute
                path="/unverifiedSchools"
                component={UnverifiedSchools}
              />
              <AuthRoute
                path="/unverifiedUserSchools"
                component={UnverifiedUserSchools}
              />
              <AuthRoute
                path="/unverifiedSchools"
                component={UnverifiedSchools}
              />
              <AuthRoute
                path="/unverifiedParents"
                component={UnverifiedParents}
              />
              <AuthRoute path="/allSources" component={AllSources} />
              <AuthRoute path="/articles" component={Articles} />
              <AuthRoute path="/classroom/teachers" component={Teachers} />
              <AuthRoute path="/classroom/students" component={Students} />
              <AuthRoute path="/classroom" component={Classroom} />
              <AuthRoute path="/schools" component={Schools} />
              <AuthRoute path="/allArticles" component={AllArticles} />
              <AuthRoute path="/quizzes" component={Quizzes} />
              <AuthRoute
                path="/articleQuizQuestions/:articleId"
                component={ArticleQuizQuestions}
              />
              <AuthRoute
                path="/quizTrainingDatasets"
                component={QuizTrainingDatasets}
              />
              <AuthRoute
                path="/readingIntervals"
                component={ReadingIntervals}
              />
              <Route render={() => <Redirect to="/articles" />} />
            </Switch>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AdminRoutes);
