import React, { useReducer, useEffect, Component } from 'react';
import {
  convertToRaw,
  ContentState,
  EditorState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  AtomicBlockUtils,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import classnames from 'classnames';

import './Editor.scss';
import { Icon, ICONS } from 'components/Icons';
import { openUploadWidget } from 'helpers/cloudinary';

export default function ({
  placeholder,
  onChange,
  html,
  autoFocus = false,
  handleReturn,
  toolbar,
  editorClassName = '',
  toolbarClassName = '',
}) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_EDITOR_STATE':
          return {
            ...state,
            editorState: action.payload,
          };
        case 'SET_FOCUSED':
          return {
            ...state,
            focused: action.payload,
          };
        default:
          return state;
      }
    },
    {
      editorState: html ? createContentState(html) : EditorState.createEmpty(),
      focused: false,
    },
  );

  function createContentState(html) {
    const contentBlock = htmlToDraft(html);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );

      return EditorState.createWithContent(contentState);
    }
  }

  useEffect(() => {
    if (state.editorState) {
      if (html) {
        if (getHTML() !== html) {
          dispatch({
            type: 'SET_EDITOR_STATE',
            payload: createContentState(html),
          });
        }
      } else {
        if (state.editorState.getCurrentContent().hasText()) {
          dispatch({
            type: 'SET_EDITOR_STATE',
            payload: EditorState.createEmpty(),
          });
        }
      }
    }
  }, [html]);

  const toolbarOpt = toolbar || {
    options: [
      'inline',
      'list',
      // 'image'
    ],
    inline: {
      options: ['bold', 'italic', 'underline'],
      bold: {
        className: 'toolbar-btn',
      },
      italic: {
        className: 'toolbar-btn',
      },
      underline: {
        className: 'toolbar-btn',
      },
    },
    list: {
      options: ['unordered'],
      unordered: {
        className: 'toolbar-btn',
      },
    },
    image: {
      className: 'toolbar-btn',
    },
  };

  function getHTML() {
    const raw = convertToRaw(state.editorState.getCurrentContent());
    return draftToHtml(raw, {}, false, ({ type, data }) => {
      //entity.data.alignment is for float using the LCR options on the image 'none' means the user clicked center
      if (type === 'IMAGE') {
        const alignment = data.alignment || 'none';
        const textAlign = alignment === 'none' ? 'center' : alignment;

        return `
              <p style="text-align:${textAlign};">
                  <img src="${data.src}" alt="${data.alt}" style="height: ${data.height};width: ${data.width}; max-width: 100%;"/>
              </p>
          `;
      }
    });
  }

  useEffect(() => {
    if (state.editorState) {
      onChange(getHTML());
    }
  }, [state.editorState]);

  const setEditorReference = (ref) => {
    if (state.focused) {
      return;
    }

    if (ref && autoFocus) {
      ref.focus();
      dispatch({ type: 'SET_FOCUSED', payload: true });
    }
  };

  const className = classnames('editor');

  return (
    <Editor
      toolbarOnFocus
      toolbar={toolbarOpt}
      wrapperClassName={className}
      editorClassName={`custom-editor ${editorClassName}`}
      toolbarClassName={`custom-toolbar ${toolbarClassName}`}
      placeholder={placeholder}
      editorState={state.editorState}
      onEditorStateChange={(editorState) => {
        // setEditorState(editorState);
        dispatch({ type: 'SET_EDITOR_STATE', payload: editorState });
      }}
      editorRef={setEditorReference}
      handleReturn={handleReturn}
      keyBindingFn={(e) => {
        // To resolve conflict with cmd + shift shortcuts
        if (
          e.keyCode === 73 &&
          e.shiftKey &&
          KeyBindingUtil.hasCommandModifier(e)
        ) {
          return 'shift-save';
        }

        return getDefaultKeyBinding(e);
      }}
      stripPastedStyles={true}
      toolbarCustomButtons={[<CustomOption />]}
    />
  );
}

class CustomOption extends Component {
  insertImage(src) {
    const { onChange, editorState } = this.props;
    const entityData = {
      src,
    };
    const entityKey = editorState
      .getCurrentContent()
      .createEntity('IMAGE', 'MUTABLE', entityData)
      .getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      ' ',
    );
    onChange(newEditorState);
  }

  onClick() {
    openUploadWidget({
      afterImageAdded: (url) => {
        this.insertImage(url);
      },
    });
  }
  render() {
    return (
      <div
        className="rdw-option-wrapper toolbar-btn"
        onClick={() => this.onClick()}
      >
        <Icon icon={ICONS.UPLOAD_PICTURE} size={20} />
      </div>
    );
  }
}
