import React, { useEffect } from 'react';

import './ManageSourcePlatformMappings.scss';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import { Icon, ICONS } from 'components/Icons';

function ManageSourcePlatformMappings({ source, handleFormChange }) {
  function onChange(name, value, index) {
    const platformMappings = [...(source.platformMappings || [])];
    platformMappings[index] = {
      ...platformMappings[index],
      [name]: value,
    };
    handleFormChange('platformMappings', platformMappings);
  }

  function renderPlatformMapping(platformMapping, index) {
    return (
      <div className="manage-platform-mappings">
        <FloatingInput
          className="manage-platform-mapping"
          key={index}
          labelName="Domain"
          value={platformMapping.domain || ''}
          onChange={(e) => onChange('domain', e.target.value, index)}
        />
        <FloatingInput
          className="manage-platform-mapping ml-3"
          key={index}
          labelName="Channel name"
          value={platformMapping.channelName || ''}
          onChange={(e) => onChange('channelName', e.target.value, index)}
        />
        <FloatingInput
          className="manage-platform-mapping ml-3"
          key={index}
          labelName="Channel domain"
          value={platformMapping.channelDomain || ''}
          onChange={(e) => onChange('channelDomain', e.target.value, index)}
        />
        <Icon
          className="av-red cursor-pointer ml-3"
          icon={ICONS.CLOSE}
          onClick={() => {
            const platformMappings = [...(source.platformMappings || [])];
            platformMappings.splice(index, 1);
            handleFormChange('platformMappings', platformMappings);
          }}
        />
      </div>
    );
  }

  return (
    <div className="manage-source-platform-mappings mb-4">
      <div>
        <span className="manage-source-platform-mappings-label">
          Platform mappings
        </span>
      </div>
      {(source.platformMappings || []).map(renderPlatformMapping)}
      <RectangularButton
        onClick={() => {
          const platformMappings = [...(source.platformMappings || [])];
          platformMappings.push({
            domain: '',
            channelName: '',
            channelDomain: '',
          });
          handleFormChange('platformMappings', platformMappings);
        }}
        noBorder
      >
        Add platform mapping
      </RectangularButton>
    </div>
  );
}

export default ManageSourcePlatformMappings;
