import React, { useEffect } from 'react';

import './ManageSourceDomains.scss';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import { Icon, ICONS } from 'components/Icons';

function ManageSourceDomains({ source, handleFormChange }) {
  function onChange(value, index) {
    const domains = [...source.domains];
    domains[index] = value;
    handleFormChange('domains', domains);
  }

  function renderSourceDomain(domain, index) {
    return (
      <div className="manage-source-domains">
        <FloatingInput
          className="manage-source-domain"
          key={index}
          value={domain}
          onChange={(e) => onChange(e.target.value, index)}
        />
        <Icon
          className="av-red cursor-pointer ml-3"
          icon={ICONS.CLOSE}
          onClick={() => {
            const domains = [...(source.domains || [])];
            domains.splice(index, 1);
            handleFormChange('domains', domains);
          }}
        />
      </div>
    );
  }

  return (
    <div className="manage-source-domains mb-4">
      <div>
        <span className="manage-source-label">Source domains</span>
      </div>
      {(source.domains || []).map(renderSourceDomain)}
      <RectangularButton
        onClick={() => {
          const domains = [...(source.domains || [])];
          domains.push('');
          handleFormChange('domains', domains);
        }}
        noBorder
      >
        Add domain
      </RectangularButton>
    </div>
  );
}

export default ManageSourceDomains;
