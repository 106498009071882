import React from 'react';
import _ from 'lodash';
import Question from './Question';

export default function Questions({ questions, loading, changeQuestions }) {
  const updateQuestion = (index, question) => {
    const clonedQuestions = _.cloneDeep(questions) || [];

    if (!clonedQuestions[index]) {
      return;
    }

    clonedQuestions[index] = question;

    changeQuestions(clonedQuestions);
  };

  const updateQuestionValue = (index, key, value) => {
    const clonedQuestions = _.cloneDeep(questions) || [];

    if (!clonedQuestions[index]) {
      return;
    }

    const question = clonedQuestions[index];

    if (
      key === 'type' &&
      value === 'SINGLE_CHOICE' &&
      Array.isArray(question['options'])
    ) {
      question['options'] = question['options'].map((option) => ({
        ...option,
        isCorrect: false,
      }));
    }

    question[key] = value;

    changeQuestions(clonedQuestions);
  };

  const deleteQuestion = (index) => {
    const clonedQuestions = _.cloneDeep(questions) || [];

    clonedQuestions.splice(index, 1);

    changeQuestions(clonedQuestions);
  };

  if (loading) {
    return (
      <div className="text-center mt-4">
        <div
          className="fa fa-spin fa-spinner text-primary"
          style={{ fontSize: '40px' }}
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      {questions.map((question, index) => (
        <Question
          index={index}
          question={question}
          updateQuestion={updateQuestion}
          updateQuestionValue={updateQuestionValue}
          deleteQuestion={deleteQuestion}
        />
      ))}
      {/* <div className="d-flex align-items-center">
        <RectangularButton
          className="add-question-button mt-4"
          iconName="ADD"
          iconSize={12}
          onClick={addQuestion}
        >
          Add Question
        </RectangularButton>
      </div> */}
    </React.Fragment>
  );
}
