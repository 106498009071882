import axios from 'axios';
import config from 'config/config.public.js';

function generateSignature(callback, params_to_sign) {
  axios({
    method: 'post',
    url: '/api/utility/getCloudinarySignature',
    params: {},
    data: {
      ...params_to_sign,
    },
  })
    .then((result) => {
      callback(result.data.signature);
    })
    .catch((result) => {});
}

let cloudinaryWidget = null;

export function openUploadWidget({ afterImageAdded }) {
  const cloudinaryOptions = {
    cloudName: config.cloudinary.cloud_name,
    uploadPreset: 'yxidy7xn',
    apiKey: config.cloudinary.api_key,
    cropping: 'true',
    cropping_aspect_ratio: 1,
    resource_type: 'image',
    uploadSignature: generateSignature,
    publicId: null,
    invalidate: true,
    sources: ['local', 'url', 'image_search'],
    folder: 'source-subscription',
  };

  if (!cloudinaryWidget) {
    cloudinaryWidget = window.cloudinary.createUploadWidget(
      cloudinaryOptions,
      (error, result) => {
        if (error) {
          return;
        }

        if (result.event == 'success') {
          afterImageAdded(result.info.secure_url);
          cloudinaryWidget.close();
        }
      },
    );
    cloudinaryWidget.open();
  } else {
    if (!cloudinaryWidget.isShowing()) {
      cloudinaryWidget.open();
    }
  }
}
